/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"

import TitleImg from "../../images/D365-business-intelligence.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Business Intelligence"
      description="Microsoft Dynamics Business Central helps you make smart business decisions quickly and with confidence."
      linkSubPageGroupID="D365Group"
      titleImg={TitleImg}
      infoList={[
        {
          title: "Better insight into business performance",
          text: `Business analytics aren't just for Fortune 500 companies anymore. In today's fiercely competitive economy, leaders of small and midsize companies are turning to business-intelligence solutions to spot trends, so they can act quickly and capitalize on opportunities.<br/><br/>Microsoft Dynamics Business Central helps you make smart business decisions quickly and with confidence. Direct access to real-time, business-critical information and a wide range of analytical tools can help you manage budgets, create and consolidate reports, and look for trends and relationships. With Microsoft Dynamics Business Central you get a good overview of your business with the ability to dig into details.`,
        },
        {
          title: "Quickly create strategic business reports",
          text: `Every business needs the regularly-scheduled profit and loss statements, in addition to deeper analysis, before expanding into new territories or adding a new product line. Microsoft Dynamics provides software that enables your managers and staff to get up-to-date information on business performance. People can use either pre-defined functions for simple reports, or use OLAP cubes when deeper analysis is needed. Your staff will be working with tools that resemble familiar Microsoft Office Outlook and Excel, so they can get up to speed quickly. They can save time on simpler reports by using customizable templates, and highlight core information with Gantt charts, drill-downs, summaries, customizable views of OLAP grids, and others.`,
        },
        {
          title: "Share information in real time",
          text: `Microsoft Dynamics solutions for business intelligence and reporting help people share business-critical information with business partners and colleagues, whether they're halfway down the hall or halfway across the globe. You can capture and share information via a Web browser, empowering team members to search, share, and collaborate in real time. And once the information is in hand, decision makers can display and analyze the data according to their unique needs.`,
        },
        {
          title: "Empower decision makers at all levels",
          text: `Microsoft Dynamics for business intelligence and reporting can play a key role in organizational efficiency and customer-service excellence. Your employees, from warehouse personnel to financial analysts, will find it easier to record, classify, and access information, and practice better decision making. For example, when a call center employee logs a certain number of complaints about a product, managers can receive an e-mail alert notifying them of a potential problem that needs quick attention.`,
        },
      ]}
    />
  )
}
